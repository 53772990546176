import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
import SecureLS from "secure-ls"
const ls = new SecureLS({ isCompression: false })

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: "",
        avatar: "",
        username: "",
        buttons: [],
		keepAlive:[],
        menu:[]
    },
    mutations: {
        login(state, payload) {
            state.token = payload.token;
        },
        logout(state) {
            state.token = "";
        },
        setAdminInfo(state, payload) {
            state.avatar = payload.avatar;
            state.username = payload.username;
        },
        setButtons(state, payload) {
            state.buttons = payload;
        },
		setKeepAlive(state,alive){
			state.keepAlive = alive
		},
        setMenu(state, payload) {
            state.menu = payload;
        }
    },
    actions: {
		setKeepAlive(ctx,keepalice){
			ctx.commit('setKeepAlive',keepalice)
		}
    },
    modules: {
    },
    plugins: [
        createPersistedState({
            key: 'token',
            storage: {
                getItem: key => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: key => ls.remove(key)
            }
        })
    ]
})
